import styled from 'styled-components'

export const Card = styled.div`
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0px 24px 32px rgba(32, 32, 32, 0.08);
  width: 100%;

  :not(:last-child) {
    margin-bottom: 32px;

    @media screen and (min-width: 768px) {
      margin-bottom: 56px;
    }
  }

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 24px;
  }

  .image-wrapper {
    @media screen and (min-width: 768px) {
      grid-column: 1/6;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
    }

    > div {
      width: 100%;
    }
  }

  .box-description {
    padding: 40px 36px;

    @media screen and (min-width: 768px) {
      grid-column: 6/12;
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: center;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;

    @media screen and (min-width: 1280px) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  .title__highlight {
    color: #FE3E6D;
    font-weight: 400;
  }

  .text {
    color: #888E93;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
`
