import React from 'react'
import Img from 'gatsby-image'

import Heading from '../../../components/Heading'
import Card from '../../../components/Card'

import * as Styled from './styled'
import { aboutData } from './data'
import getFluidImage from '../../../hooks/getFluidImage'

const SectionAboutCard = (): JSX.Element => (
  <Styled.Section>
    <div className="container">
      <Styled.HeadingWrapper>
        <Heading headingLevel="h2" size="xlarge" center>
          Gestão de cobrança completa. Não complexa.
        </Heading>

        <Styled.Text>
          Nunca foi tão fácil cobrar e receber dos seus clientes. Na sua conta
          Cora, você tem tudo o que precisa para gerenciar suas cobranças de
          forma simples e inteligente.
        </Styled.Text>
      </Styled.HeadingWrapper>

      <Styled.CardsWrapper>
        {aboutData.map(({ title, info, image }: IAboutData, index: number) => (
          <Card key={`management-features-${index}`}>
            <div className="image-wrapper">
              <div>
                <Img fluid={getFluidImage(image)} />
              </div>
            </div>
            <div className="box-description">
              <h5 className="title">{title}</h5>
              <p className="text">
                {info}
              </p>
            </div>
          </Card>
        ))}
      </Styled.CardsWrapper>
    </div>
  </Styled.Section>
)


export default SectionAboutCard
