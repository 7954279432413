import styled from 'styled-components'

export const Section = styled.section`
  background: linear-gradient(180deg, #F0F4F8 0%, rgba(240, 244, 248, 0) 100%);
  padding-bottom: 50px;

  @media screen and (min-width: 768px) {
    padding: 80px 0;
  }

  @media screen and (min-width: 1920px) {
    padding: 110px 0;
  }
`

export const LeftSide = styled.div`
  position: relative;
  grid-column: 1/-1;
  padding: 24px 0;

  @media screen and (min-width: 375px) {
    padding: 24px 0 32px;
  }

  @media screen and (min-width: 768px) {
    grid-column: 1/6;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
  }

  @media screen and (min-width: 1280px) {
    grid-column: 2/8;
  }
`

export const RightSide = styled.div`
  @media screen and (min-width: 768px) {
    grid-column: 6/-1;
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: 1280px) {
    grid-column: 8/-1;
  }

  > div {
    width: 100%;
  }
`

export const HighlightTitle = styled.span`
  color: #FE3E6D;
  font-size: 32px;
  line-height: 40px;

  @media screen and (min-width: 768px) {
    margin-bottom: 16px;
  }
`

export const Text = styled.span`
  display: flex;
  color: #888E93;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;

  @media screen and (min-width: 768px) {
    font-size: 22px;
    line-height: 32px;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 24px;

  &.sticky {
    position: fixed;
    top: 50px;
    width: calc(100vw - 48px);
    z-index: 2;
  }
`
