const faq = [
  {
    question: 'Preciso ter CNPJ para emitir boletos com a Cora?',
    answer: 'Sim, é necessário que sua empresa tenha CNPJ para ter o cadastro aprovado na Cora. Nosso app é destinado para autônomos, profissionais liberais e donos de pequenos e médios negócios, com empresas constituídas.',
    open: false,
  },
  {
    question: 'Vou pagar algum tipo de taxa para abrir a conta na Cora?',
    answer: 'Não. O aplicativo da Cora é uma solução gratuita. Nenhum valor será cobrado para abertura ou manutenção da conta. Você também tem gratuidade em transferências Pix e TED, além de diversos serviços gratuitos para sua empresa, como a emissão de boletos (com cobrança de R$1,90 por boleto, após a 100ª cobrança compensada no mês).',
    open: false,
  },
  {
    question: 'Posso integrar a Cora com outros sistemas?',
    answer: 'Sim. É possível integrar a Cora com ERPs e sistemas contábeis elegíveis ao nosso programa de parcerias. Neste caso a integração é realizada por meio de nossa API e é cobrado um valor de acordo com o volume de cobranças. Para saber mais, preencha este formulário de contato.',
    open: false,
  },
  {
    question: 'É cobrado algum tipo de taxa para receber boletos gerados pelo app?',
    answer: 'Sim. Boletos emitidos pelo app são gratuitos. No entanto, a gratuidade está limitada a 100 boletos compensados mensalmente. Ou seja, a partir da 101º cobrança via boleto compensada no mesmo mês, haverá uma tarifa de R$1,90 por boleto excedente, que será debitada diretamente da conta Cora.',
    open: false,
  },
];

export default faq;
