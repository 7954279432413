import styled from 'styled-components'

export const Section = styled.section`
  padding-bottom: 120px;
`

export const HeadingWrapper = styled.div`
  grid-column: 2/12;
  display: flex;
  flex-direction: column;
  align-items: center;

  small {
    font-size: 22px;
    line-height: 32px;
    margin-top: 24px;
  }
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 90px;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column: 1/-1;
    grid-gap: 24px;
    grid-template-areas: "card1 card2 card3";
  }

  .card1 {
    grid-area: card1;
  }

  .card2 {
    grid-area: card2;
  }

  .card3 {
    grid-area: card3;
  }
`

export const Card = styled.div`
  padding: 64px 32px;
  background-color: #FFF;
  box-shadow: 0px 24px 32px rgba(32, 32, 32, 0.08);
  border-radius: 5px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @media screen and (min-width: 768px) {
    padding: 64px 62px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 1920px) {
    padding: 96px 142px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  picture {
    display: flex;
    justify-content: center;
  }

  span {
    display: block;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #888E93;
    margin-top: 32px;

    @media screen and (min-width: 1920px) {
      margin-top: 56px;
    }
  }
`
