import styled from 'styled-components'

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 43px 0 60px;

  @media screen and (min-width: 768px) {
    grid-column: 1/-1;
    padding: 80px 0;
  }

  @media screen and (min-width: 1280px) {
    grid-column: 2/11;
    padding: 180px 0 175px;
  }

  @media screen and (min-width: 1920px) {
    grid-column: 2/11;
    padding: 225px 0 150px;
  }
`

export const Text = styled.span`
  color: #FFF;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  padding: 24px 0 32px;

  @media screen and (min-width: 768px) {
    font-size: 22px;
    line-height: 32px;
    margin-top: 24px;
    padding: 0;
  }
`

export const ShadowBottom = styled.div`
  visibility: hidden;
  opacity: 0;

  @media screen and (min-width: 768px) {
    background: linear-gradient(rgba(32, 32, 32, 0) 0%, #202020 100%);
    height: 144px;
    opacity: 0.5;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    visibility: visible;
  }
`

export const ButtonWrapper = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    margin-top: 40px;
  }
`
