import React, { useRef, useState, useEffect } from 'react'

import Heading from '../../../components/Heading'
import Button from '../../../components/Button'

import * as Styled from './styled'
import getStaticImage from '../../../hooks/getStaticImage'

const SectionHero = ({ toggle }: SectionHeroProps): JSX.Element => {
  const buttonRef = useRef(null) as any
  const [sticky, setSticky] = useState<boolean>(false)

  const onScroll = () => {
    const offsetTopElement = buttonRef?.current?.offsetTop

    if (window.pageYOffset >= offsetTopElement) {
      setSticky(true)
    } else if (window.pageYOffset < offsetTopElement) {
      setSticky(false)
    }
  }

  useEffect(() => {
    if (window.innerWidth > 520) { return }

    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [])

  return (
    <Styled.Section>
      <div className="container">
        <Styled.LeftSide>
          <Styled.HighlightTitle>Na Cora, cobrar é fácil.</Styled.HighlightTitle>
          <Heading headingLevel="h1" size="xlarge">Receber também.</Heading>
          <Styled.Text className="s-text-seo">Muito além da emissão de boletos, na Cora você tem acesso a funcionalidades desenvolvidas para simplificar sua gestão de cobranças e economizar seu tempo.</Styled.Text>
          <Styled.ButtonWrapper ref={buttonRef} className={sticky ? 'sticky' : ''}>
            <Button handleClick={toggle} size="large" mobileShadow={sticky ? true : false}>Quero Ser Cora</Button>
          </Styled.ButtonWrapper>
        </Styled.LeftSide>
        <Styled.RightSide>
          <div>
            <img src={getStaticImage('hero-charge-management.png')} style={{ width: '100%' }} />
          </div>
        </Styled.RightSide>
      </div>
    </Styled.Section>
  )
}

export default SectionHero
