export const aboutData = [
  {
    title: 'Dashboard de Gestão de Cobranças',
    info: `Acompanhe em um único lugar todas as suas cobranças pela data de
    vencimento e pelo status de pagamento. Além disso, visualize
    gráficos com comparativos de recebimento entre o mês atual e o
    mês anterior.`,
    image: 'image_1.png',
  },
  {
    title: 'Notificações automáticas de cobrança',
    info: `Programe o envio automático de lembretes de cobranças para seus
    clientes antes, depois ou na data de vencimento dos boletos. Com
    a cobrança automatizada, você evita que os seus clientes atrasem
    o pagamento e reduz a inadimplência.`,
    image: 'image_2.png',
  },
  {
    title: 'Boletos parcelados',
    info: `Ofereça mais facilidade de pagamento para seus clientes com a
    emissão de boletos parcelados! Na Cora, você tem a opção de
    emitir cobranças em até 12 parcelas de uma única vez.`,
    image: 'image_3.png',
  },
  {
    title: 'Cobranças com Descontos, Juros e Multa',
    info: `Adicione juros, multas e descontos nas cobranças por boletos
    para evitar atrasos e otimizar os pagamentos. Também é possível
    escolher se descontos e multas são cobrados de modo percentual
    ou com valor fixo.`,
    image: 'image_4.png',
  },
  {
    title: 'Cobranças recorrentes',
    info: `Programe cobranças que se repetem mensalmente pelo período de
    tempo que você determinar. Ao programar essas cobranças, elas
    serão enviadas automaticamente para o e-mail do seu cliente 7
    dias antes do vencimento.`,
    image: 'image_5.png',
  },
  {
    title: 'Emissão gratuita de boletos pelo app',
    info: `Gere boletos registrados – à vista, parcelados ou com recorrência. Pague apenas R$1,90 por boleto, a partir da 100ª cobrança compensada.`,
    image: 'image_6.png',
  },
]
