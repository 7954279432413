import styled from 'styled-components'

export const Section = styled.section`
  padding: 80px 0;
  background: linear-gradient(180deg, #DEE4E9 0%, rgba(255, 255, 255, 0) 75.93%);

  @media screen and (min-width: 768px) {
    padding: 120px 0;
  }

  @media screen and (min-width: 1280px) {
    padding: 160px 0;
  }
`

export const HeadingWrapper = styled.div`
  grid-column: 3/11;
`

export const Text = styled.small`
  font-size: 16px;
  line-height: 24px;
  color: #888E93;
  margin-top: 24px;
  display: flex;

  @media screen and (min-width: 768px) {
    font-size: 22px;
    line-height: 32px;
    text-align: center;
  }
`

export const CardsWrapper = styled.div`
  grid-column: 2/12;
  margin-top: 48px;

  @media screen and (min-width: 1280px) {
    grid-column: 3/11;
    margin-top: 80px;
  }
`
