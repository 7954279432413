import React from 'react'

import Heading from '../../../components/Heading'

import * as Styled from './styled'

const SectionPayment = (): JSX.Element => (
  <Styled.Section>
    <div className="container">
      <Styled.HeadingWrapper>
        <Heading headingLevel="h3" size="large" >Como você recebe os pagamentos?</Heading>

        <small>Basta abrir sua Conta PJ gratuita na Cora.</small>
      </Styled.HeadingWrapper>

      <Styled.CardsWrapper>
        <Styled.Card className="card1">
          <picture>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#1EA4F3" />
              <path d="M24 26C24 25.4477 23.5523 25 23 25V26H24ZM24 54V26H23V54H24ZM23 55C23.5523 55 24 54.5523 24 54H23V55ZM22 54C22 54.5523 22.4477 55 23 55V54H22ZM22 26V54H23V26H22ZM23 25C22.4477 25 22 25.4477 22 26H23V25ZM58 26C58 25.4477 57.5523 25 57 25V26H58ZM58 54V26H57V54H58ZM57 55C57.5523 55 58 54.5523 58 54H57V55ZM56 54C56 54.5523 56.4477 55 57 55V54H56ZM56 26V54H57V26H56ZM57 25C56.4477 25 56 25.4477 56 26H57V25ZM30 26C30 25.4477 29.5523 25 29 25V26H30ZM30 46V26H29V46H30ZM29 47C29.5523 47 30 46.5523 30 46H29V47ZM28 46C28 46.5523 28.4477 47 29 47V46H28ZM28 26V46H29V26H28ZM29 25C28.4477 25 28 25.4477 28 26H29V25ZM35.5 26C35.5 25.4477 35.0523 25 34.5 25V26H35.5ZM35.5 46V26H34.5V46H35.5ZM34.5 47C35.0523 47 35.5 46.5523 35.5 46H34.5V47ZM33.5 46C33.5 46.5523 33.9477 47 34.5 47V46H33.5ZM33.5 26V46H34.5V26H33.5ZM34.5 25C33.9477 25 33.5 25.4477 33.5 26H34.5V25ZM41 26C41 25.4477 40.5523 25 40 25V26H41ZM41 46V26H40V46H41ZM40 47C40.5523 47 41 46.5523 41 46H40V47ZM39 46C39 46.5523 39.4477 47 40 47V46H39ZM39 26V46H40V26H39ZM40 25C39.4477 25 39 25.4477 39 26H40V25ZM46.5 26C46.5 25.4477 46.0523 25 45.5 25V26H46.5ZM46.5 46V26H45.5V46H46.5ZM45.5 47C46.0523 47 46.5 46.5523 46.5 46H45.5V47ZM44.5 46C44.5 46.5523 44.9477 47 45.5 47V46H44.5ZM44.5 26V46H45.5V26H44.5ZM45.5 25C44.9477 25 44.5 25.4477 44.5 26H45.5V25ZM52 26C52 25.4477 51.5523 25 51 25V26H52ZM52 46V26H51V46H52ZM51 47C51.5523 47 52 46.5523 52 46H51V47ZM50 46C50 46.5523 50.4477 47 51 47V46H50ZM50 26V46H51V26H50ZM51 25C50.4477 25 50 25.4477 50 26H51V25Z" fill="white" />
            </svg>
          </picture>

          <span>Você abre uma conta para sua empresa na Cora e começa a emitir boletos e fazer a sua gestão de cobranças</span>
        </Styled.Card>

        <Styled.Card className="card2">
          <picture>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#1EA4F3" />
              <path d="M54.002 39.998H25.998M54.002 39.998L43.998 50M54.002 39.998L43.998 29.998" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </picture>

          <span>Você envia os boletos para seus clientes que efetuam o pagamento das cobranças. Também pode optar por receber via transferência (TED ou PIX).</span>
        </Styled.Card>

        <Styled.Card className="card3">
          <picture>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#1EA4F3" />
              <path d="M44.7759 24.756C45.1147 24.69 45.4613 24.648 45.8198 24.648C48.8895 24.648 51.3781 27.1892 51.3781 30.324C51.3781 33.4588 48.8895 36 45.8198 36C43.1023 35.9974 40.7875 33.9833 40.3535 31.244M44.7759 24.756C42.1578 25.2656 40.2632 27.6023 40.2614 30.324C40.2614 30.638 40.3065 30.942 40.3535 31.244M44.7759 24.756C44.3418 22.0167 42.027 20.0026 39.3096 20C36.2398 20 33.7512 22.5412 33.7512 25.676C33.7512 28.8108 36.2398 31.352 39.3096 31.352C39.668 31.352 40.0146 31.31 40.3535 31.244M29.8342 56V44C29.8342 42.8954 28.9573 42 27.8756 42H23.9585C22.8769 42 22 42.8954 22 44V56C22 57.1046 22.8769 58 23.9585 58H27.8756C28.9573 58 29.8342 57.1046 29.8342 56ZM29.8342 56H32.7876C33.4275 55.9997 34.065 56.0803 34.6855 56.24L39.8756 57.564C41.0011 57.8516 42.1751 57.8803 43.3128 57.648L49.0513 56.476C50.5679 56.1658 51.9607 55.4045 53.0546 54.288L57.1147 50.142C58.2302 49.0209 58.3004 47.2074 57.2749 45.9999C56.2494 44.7924 54.4814 44.6068 53.2367 45.576L48.5049 49.2C47.8273 49.72 47.0027 50 46.1547 50H41.5854H44.4938C46.1331 50 47.461 48.644 47.461 46.97V46.364C47.461 44.974 46.5346 43.762 45.2146 43.426L40.7256 42.28C38.2115 41.6369 35.5483 42.1083 33.3928 43.578L29.8342 46" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </picture>

          <span>Os pagamentos são creditados na Cora, e você pode movimentar o dinheiro livremente: sacar, pagar contas ou transferir para outros bancos.</span>
        </Styled.Card>
      </Styled.CardsWrapper>
    </div>
  </Styled.Section>
)

export default SectionPayment
