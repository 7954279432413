import React, { FC, ReactNode } from 'react'

import * as Styled from './styled'

interface CardProps {
  children: ReactNode
}

const Card: FC<CardProps> = ({ children }: CardProps) => (
  <Styled.Card>
    {children}
  </Styled.Card>
)

export default Card
