import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import Heading from '../../../components/Heading'
import LinkButton from '../../../components/LinkButton'

import * as Styled from './styled'

const SectionBackground = (): JSX.Element => {
  const {
    mobileImage,
    tabletImage,
    desktopImage,
    desktopLargeImage,
  } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(
          relativePath: {
            eq: "new/charge-management/background-section-mobile.png"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 490, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tabletImage: file(
          relativePath: {
            eq: "new/charge-management/background-section-tablet.png"
          }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktopImage: file(
          relativePath: {
            eq: "new/charge-management/background-section-desktop.png"
          }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktopLargeImage: file(
          relativePath: {
            eq: "new/charge-management/background-section-desktop-large.png"
          }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...tabletImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 1024px)`,
    },
    {
      ...desktopLargeImage.childImageSharp.fluid,
      media: `(min-width: 1920px)`,
    },
  ]

  return (
    <BackgroundImage Tag="section" fluid={sources}>
      <div className="container">
        <Styled.SectionContent>
          <Heading headingLevel="h3" size="xlarge" dark>
            Gestão de cobranças integrada.
          </Heading>

          <Styled.Text>
            Além de emitir boletos gratuitos no aplicativo, você também pode
            emitir cobranças via integração da sua conta com ERPs e sistemas
            contábeis que são parceiros da Cora. Nesse caso, é cobrado um valor
            de acordo com o volume de cobranças.
          </Styled.Text>

          <Styled.ButtonWrapper>
            <LinkButton
              href="https://comunidade.cora.com.br/parcerias#203da0a"
              mobileShadow
              target="_blank"
              rel="noopener noreferrer"
            >
              Quero saber mais
            </LinkButton>
          </Styled.ButtonWrapper>
        </Styled.SectionContent>
      </div>
    </BackgroundImage>
  )
}

export default SectionBackground
